// extracted by mini-css-extract-plugin
export var headerContainer = "products-module--headerContainer--fdV-_";
export var tabsContainer = "products-module--tabsContainer--3uYwy";
export var productsContainer = "products-module--productsContainer--SD5qR";
export var productTitle = "products-module--productTitle--8wrWL";
export var description = "products-module--description--9GhSj";
export var descriptionContainer = "products-module--descriptionContainer--3MQrR";
export var imageContainer = "products-module--imageContainer--Yevch";
export var tabIndicator = "products-module--tabIndicator--2Y3Yc";
export var tab = "products-module--tab--3g7xz";
export var fab = "products-module--fab--2_PF8";
export var displayNone = "products-module--displayNone--2InCn";
export var collectionFilters = "products-module--collectionFilters--2dgxQ";
export var filterSelect = "products-module--filterSelect--3S5KT";
export var formLabel = "products-module--formLabel--39XAB";
export var formSelect = "products-module--formSelect--3fFP9";
export var selectItem = "products-module--selectItem--37Qtb";
export var tabs = "products-module--tabs--eY3_B";