import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Hidden from '@material-ui/core/Hidden';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { navigate, useLocation } from "@reach/router";
import GlobalLayout from "../../layouts/globalLayout";
import * as styles from './products.module.css';
import { ImFilter } from 'react-icons/im';
import { Fab, Grid } from "@material-ui/core";
import ProductGrid from "../../components/ProductGrid/productGrid";
import { useTopMostDiv } from "../../components/helperHooks";

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const defaultContext = {
    color: ['', () => { }],
    scent: ['', () => { }],
    lidded: ['', () => { }],
    sort: ['', () => { }],
    lockedProductHandles: ['meet-cute-scent-kit']
}
export const ProductFilterContext = React.createContext(defaultContext);

export default function Products() {
    const { allShopifyCollection } = useStaticQuery(
        graphql`
            query {
                allShopifyCollection(filter: {handle: {eq: "vintage-glassware-candles"}}) {
                    nodes {
                        title
                    }
                }
            }
        `);
    const [colorFilter, setColorFilter] = useState('');
    const [scentFilter, setScentFilter] = useState('');
    const [liddedFilter, setLidFilter] = useState('');
    const [sortFunc, setSortFunc] = useState('');
    const [tabDrawerOpen, setTabDrawerOpen] = useState(false);

    const collections = allShopifyCollection.nodes ? allShopifyCollection.nodes.map(collection => collection.title) : [];

    const topmostIndex = useTopMostDiv(collections);
    const url = useLocation();

    const handleChange = (event, newValue) => {
        navigate(`/shop/products#${collections[newValue]}`);
    };

    const tabsContainer = (
        <Tabs
            orientation="vertical"
            variant="scrollable"
            value={topmostIndex}
            onChange={handleChange}
            aria-label="Products Menu"
            className={styles.tabs}
            TabIndicatorProps={{
                className: styles.tabIndicator
            }}
        >
            {
                collections.map((tag, index) => (
                    <div key={tag}>
                        <Tab key={tag} label={tag} {...a11yProps(index)} className={styles.tab} />
                        <div className={styles.collectionFilters}>
                            <FormControl className={styles.filterSelect}>
                                <InputLabel id="color-select-label" className={styles.formLabel}>Color</InputLabel>
                                <Select
                                    labelId="color-select"
                                    id="color-select"
                                    className={styles.formSelect}
                                    value={colorFilter}
                                    onChange={({ target }) => {
                                        setColorFilter(target.value);
                                    }}
                                >
                                    <MenuItem value={''} className={styles.selectItem}>All Colors</MenuItem>
                                    <MenuItem value={'red candle'} className={styles.selectItem}>Red</MenuItem>
                                    <MenuItem value={'amber candle'} className={styles.selectItem}>Amber</MenuItem>
                                    <MenuItem value={'white candle'} className={styles.selectItem}>White</MenuItem>
                                    <MenuItem value={'blue candle'} className={styles.selectItem}>Blue</MenuItem>
                                    <MenuItem value={'orange candle'} className={styles.selectItem}>Orange</MenuItem>
                                    <MenuItem value={'clear candle'} className={styles.selectItem}>Clear</MenuItem>
                                    <MenuItem value={'green candle'} className={styles.selectItem}>Green</MenuItem>
                                    <MenuItem value={'pink candle'} className={styles.selectItem}>Pink</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className={styles.filterSelect}>
                                <InputLabel id="scent-select-label" className={styles.formLabel}>Scent</InputLabel>
                                <Select
                                    labelId="scent-select"
                                    id="scent-select"
                                    className={styles.formSelect}
                                    value={scentFilter}
                                    onChange={({ target }) => {
                                        setScentFilter(target.value)
                                    }}
                                >
                                    <MenuItem value={''} className={styles.selectItem}>All Scents</MenuItem>
                                    <MenuItem value={'kindred spirit'} className={styles.selectItem}>Kindred Spirit</MenuItem>
                                    <MenuItem value={'witchy woman'} className={styles.selectItem}>Witchy Woman</MenuItem>
                                    <MenuItem value={'hiking buddy'} className={styles.selectItem}>Hiking Buddy</MenuItem>
                                    <MenuItem value={'girl next door'} className={styles.selectItem}>Girl Next Door</MenuItem>
                                    <MenuItem value={'magical mind'} className={styles.selectItem}>Magical Mind</MenuItem>
                                    <MenuItem value={'bad boy'} className={styles.selectItem}>Bad Boy</MenuItem>
                                    <MenuItem value={'mamas boy'} className={styles.selectItem}>Mamas Boy</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className={styles.filterSelect}>
                                <InputLabel id="lidded-select-label" className={styles.formLabel}>Lidded</InputLabel>
                                <Select
                                    labelId="lidded-select"
                                    id="lidded-select"
                                    className={styles.formSelect}
                                    value={liddedFilter}
                                    onChange={({ target }) => { setLidFilter(target.value) }}
                                >
                                    <MenuItem value={''} className={styles.selectItem}>All Lid Types</MenuItem>
                                    <MenuItem value={'lidded'} className={styles.selectItem}>Lid</MenuItem>
                                    <MenuItem value={'no lid'} className={styles.selectItem}>No Lid</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className={styles.filterSelect}>
                                <InputLabel id="sort-select-label" className={styles.formLabel}>Sort</InputLabel>
                                <Select
                                    labelId="sort-select"
                                    id="sort-select"
                                    className={styles.formSelect}
                                    value={sortFunc}
                                    onChange={({ target }) => {
                                        setSortFunc(target.value);
                                    }}
                                >
                                    <MenuItem value={''} className={styles.selectItem}>No Sorting</MenuItem>
                                    <MenuItem value={'priceHighToLow'} className={styles.selectItem}>Highest to Lowest Price</MenuItem>
                                    <MenuItem value={'priceLowToHigh'} className={styles.selectItem}>Lowest to Highest Price</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                ))
            }
        </Tabs>
    );

    return (
        <GlobalLayout>
            <ProductFilterContext.Provider
                value={{
                    color: [colorFilter, setColorFilter],
                    scent: [scentFilter, setScentFilter],
                    lidded: [liddedFilter, setLidFilter],
                    sort: [sortFunc, setSortFunc],
                    lockedProductHandles: defaultContext.lockedProductHandles
                }}
            >
                <Grid container>
                    <Hidden smDown>
                        <Grid item xs={2} className={styles.tabsContainer}>
                            {tabsContainer}
                        </Grid>
                    </Hidden>
                    <Hidden mdUp>
                        <Fab onClick={() => { setTabDrawerOpen(!tabDrawerOpen); }} className={styles.fab}>
                            <ImFilter />
                        </Fab>
                        <SwipeableDrawer className={styles.tabsDrawer} anchor="left" open={tabDrawerOpen} onClose={() => { setTabDrawerOpen(false); }}>
                            {tabsContainer}
                        </SwipeableDrawer>
                    </Hidden>
                    <Grid item md={8} xs={12} className={styles.productsContainer}>
                        <ProductGrid />
                    </Grid>
                </Grid>
            </ProductFilterContext.Provider>
        </GlobalLayout>
    );
}