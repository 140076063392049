import React, { useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { StoreContext } from '../../provider/StoreProvider'
import * as styles from './productGrid.module.css';
import { GatsbyImage } from "gatsby-plugin-image";
import PictureCardWithBorder from '../PictureCardWithBorder/pictureCardWithBorder';
import { getPrice } from '../../utils/shopifyUtils';
import { useEffect } from 'react';
import { useState } from 'react';
import { useShopifyProductIsSold } from '../helperHooks';
import { ProductFilterContext } from '../../pages/shop/products';

const ProductGrid = () => {
  const { allShopifyCollection } = useStaticQuery(
    graphql`{
  allShopifyCollection(filter: {handle: {eq: "vintage-glassware-candles"}}) {
    edges {
      node {
        description
        title
        handle
        products {
          id
          title
          handle
          createdAt
          tags
          images {
            id
            originalSrc
            gatsbyImageData(width: 910, layout: CONSTRAINED)
          }
          variants {
            price
            id
            shopifyId
            legacyResourceId
          }
          totalInventory
        }
      }
    }
  }
}
`
  )

  return (
    <div className={styles.container}>
      {allShopifyCollection.edges ? (
        allShopifyCollection.edges.map(
          ({
            node: {
              description,
              title,
              products,
              handle
            },
          }) => (
            <Collection key={handle} handle={handle} description={description} title={title} products={products} id={handle} />
          )
        )
      ) : (
        <p>No Products found!</p>
      )}
    </div>
  )
}

export default ProductGrid

const sortTypes = {
  default: (a, b) => (parseInt(b.totalInventory) - parseInt(a.totalInventory)),
  priceLowToHigh: (a, b) => (parseInt(a.variants[0].price) - parseInt(b.variants[0].price)),
  priceHighToLow: (a, b) => (parseInt(b.variants[0].price) - parseInt(a.variants[0].price))
}

const Collection = ({
  description,
  title,
  products,
  id,
  handle
}) => {
  const filters = useContext(ProductFilterContext);
  const { color, scent, lidded, sort, lockedProductHandles } = filters;
  const [colorFilter, setColorFilter] = color;
  const [scentFilter, setScentFilter] = scent;
  const [liddedFilter, setLidFilter] = lidded;
  const [sortFunc, setSortFunc] = sort;

  const productsSansLockedProducts = products.filter((product => !lockedProductHandles.includes(product.handle)));
  const lockedProducts = products.filter((product => lockedProductHandles.includes(product.handle)));

  return (
    <div className={styles.collection}>
      <span className={styles.anchor} id={id}></span>
      <h1 className={styles.collectionTitle}>{title}</h1>
      <p className={styles.collectionDescription}>{description}</p>
      {handle === 'vintage-glassware-candles' && <div className={styles.collectionFilters}>
        <FormControl className={styles.filterSelect}>
          <InputLabel id="color-select-label" className={styles.formLabel}>Color</InputLabel>
          <Select
            labelId="color-select"
            id="color-select"
            className={styles.formSelect}
            value={colorFilter}
            onChange={({ target }) => { setColorFilter(target.value) }}
          >
            <MenuItem value={''} className={styles.selectItem}>All Colors</MenuItem>
            <MenuItem value={'red candle'} className={styles.selectItem}>Red</MenuItem>
            <MenuItem value={'amber candle'} className={styles.selectItem}>Amber</MenuItem>
            <MenuItem value={'white candle'} className={styles.selectItem}>White</MenuItem>
            <MenuItem value={'blue candle'} className={styles.selectItem}>Blue</MenuItem>
            <MenuItem value={'orange candle'} className={styles.selectItem}>Orange</MenuItem>
            <MenuItem value={'clear candle'} className={styles.selectItem}>Clear</MenuItem>
            <MenuItem value={'green candle'} className={styles.selectItem}>Green</MenuItem>
            <MenuItem value={'pink candle'} className={styles.selectItem}>Pink</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={styles.filterSelect}>
          <InputLabel id="scent-select-label" className={styles.formLabel}>Scent</InputLabel>
          <Select
            labelId="scent-select"
            id="scent-select"
            className={styles.formSelect}
            value={scentFilter}
            onChange={({ target }) => { setScentFilter(target.value) }}
          >
            <MenuItem value={''} className={styles.selectItem}>All Scents</MenuItem>
            <MenuItem value={'kindred spirit'} className={styles.selectItem}>Kindred Spirit</MenuItem>
            <MenuItem value={'witchy woman'} className={styles.selectItem}>Witchy Woman</MenuItem>
            <MenuItem value={'hiking buddy'} className={styles.selectItem}>Hiking Buddy</MenuItem>
            <MenuItem value={'girl next door'} className={styles.selectItem}>Girl Next Door</MenuItem>
            <MenuItem value={'magical mind'} className={styles.selectItem}>Magical Mind</MenuItem>
            <MenuItem value={'bad boy'} className={styles.selectItem}>Bad Boy</MenuItem>
            <MenuItem value={'mamas boy'} className={styles.selectItem}>Mamas Boy</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={styles.filterSelect}>
          <InputLabel id="lidded-select-label" className={styles.formLabel}>Lidded</InputLabel>
          <Select
            labelId="lidded-select"
            id="lidded-select"
            className={styles.formSelect}
            value={liddedFilter}
            onChange={({ target }) => { setLidFilter(target.value) }}
          >
            <MenuItem value={''} className={styles.selectItem}>All Lid Types</MenuItem>
            <MenuItem value={'lidded'} className={styles.selectItem}>Lid</MenuItem>
            <MenuItem value={'no lid'} className={styles.selectItem}>No Lid</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={styles.filterSelect}>
          <InputLabel id="sort-select-label" className={styles.formLabel}>Sort</InputLabel>
          <Select
            labelId="sort-select"
            id="sort-select"
            className={styles.formSelect}
            value={sortFunc}
            onChange={({ target }) => { setSortFunc(target.value) }}
          >
            <MenuItem value={''} className={styles.selectItem}>No Sorting</MenuItem>
            <MenuItem value={'priceHighToLow'} className={styles.selectItem}>Highest to Lowest Price</MenuItem>
            <MenuItem value={'priceLowToHigh'} className={styles.selectItem}>Lowest to Highest Price</MenuItem>
          </Select>
        </FormControl>
      </div>}
      <div className={styles.grid}>
        {
          lockedProducts.concat(productsSansLockedProducts.sort(sortFunc !== '' ? sortTypes[sortFunc] : sortTypes.default)).reduce((accum, product) => {
            let colorFilterWorks = true;
            let scentFilterWorks = true;
            let liddedFilterWorks = true;

            if (colorFilter !== '' && product.tags.find(tag => tag.toLowerCase() === colorFilter)) {
              colorFilterWorks = true;
            } else if (colorFilter !== '') {
              colorFilterWorks = false;
            }

            if (scentFilter !== '' && product.tags.find(tag => tag.toLowerCase().includes(scentFilter))) {
              scentFilterWorks = true;
            } else if (scentFilter !== '') {
              scentFilterWorks = false;
            }

            if (liddedFilter !== '' && product.tags.find(tag => tag.toLowerCase().includes(liddedFilter))) {
              liddedFilterWorks = true;
            } else if (liddedFilter !== '') {
              liddedFilterWorks = false;
            }

            if (colorFilterWorks && scentFilterWorks && liddedFilterWorks) {
              accum.push(<Product key={product.handle} {...product} />);
            }

            return accum;
          }, [])
        }
      </div>
    </div>
  )
}

const Product = ({
  id,
  handle,
  title,
  images: [firstImage, secondImage],
  variants: [firstVariant],
  totalInventory
}) => {
  const {
    addVariantToCart,
    setIsOpen,
    checkout
  } = useContext(StoreContext);

  const isSold = useShopifyProductIsSold(handle);

  const itemInCart = checkout.lineItems.find(item => item.variant.product.handle === handle);
  const isMoreItemAvailable = itemInCart && itemInCart.quantity < totalInventory;
  const splitTitle = title.split(' ');

  return (
    <div className={styles.product} key={id}>
      <PictureCardWithBorder
        image={firstImage ? firstImage.gatsbyImageData : undefined}
        image2={secondImage ? secondImage.gatsbyImageData : undefined}
        buttonText={itemInCart && isMoreItemAvailable ? "ADD ANOTHER TO BAG" : "ADD TO BAG"}
        buttonDisabled={isSold || (itemInCart && !isMoreItemAvailable)}
        buttonTextDisabled={isSold ? "SOLD" : "ALREADY IN BAG"}
        href={`/shop/${handle}/`}
        text={title}
        subText={[getPrice(firstVariant.price)]}
        buttonClick={() => {
          if (!(isSold || (itemInCart && !isMoreItemAvailable))) {
            addVariantToCart(btoa(firstVariant.shopifyId), 1);
            setIsOpen(true);
          }
        }}
      />
      {/* {firstImage && firstImage.localFile && (
          <Img
            fluid={firstImage.localFile.childImageSharp.fluid}
            alt={handle}
            className={styles.productImage}
          />
        )}
        <div className={styles.caption}>
          <div className={styles.title}>{
            splitTitle.length > 1 ? 
              splitTitle.map((word, index) => 
                (index > 0 
                  ? <span key={word}>{word}</span> : `${word} `)) 
                  : splitTitle[0]
            }
          </div>
          <div className={styles.priceTag}>{getPrice(firstVariant.price)}</div>
        </div> */}

    </div>
  );
}